import { signal, Signal } from "@preact/signals";
import { h, ComponentChildren, createContext } from "preact";

export type IceAnimationHeightContextValues = {
  stopHeightAnimation: Signal<boolean>;
};

export const IceAnimationHeightContext = createContext<Partial<IceAnimationHeightContextValues>>({});

export const IceAnimationHeightProvider = ({ children }: { children: ComponentChildren }) => {
  const stopHeightAnimation = signal<boolean>(false);
  return (
    <IceAnimationHeightContext.Provider value={{ stopHeightAnimation }}>{children}</IceAnimationHeightContext.Provider>
  );
};
