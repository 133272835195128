import { h, render } from "preact";
import type {
  MbbBundle,
  ShoppingCartTransferModel,
  Subscription,
  VoiceAddToCartEvent,
  voiceShopStoreStateType,
} from "../shop-types";
import { useEffect, useState } from "preact/hooks";
import {
  Mbb_SHOPPING_CART_EVENTS,
  SHOPPING_CART_EVENTS,
} from "Scripts/Src/Components/ShoppingCart/Components/shopping-cart-constants";
import { fetchProductBlockDataService } from "../Helpers/subscription-builder.service";
import { MapProductDataToSubScription } from "../shop-cart.store";
import { showHeader } from "../Helpers/shopping-cart-utils";
import type { ProductBlockDataModel } from "../Helpers/subscription-builder.types";
import { DialogLoader } from "./Dialogs/dialog-loader";

export type ShoppingCartEventHandlerProps = {
  useVoiceStore: voiceShopStoreStateType;
};

export enum DialogState {
  none = 0,
  default = 1,
  external = 2,
  maxOfferings = 3,
}

const isAnyServiceIsDefaultSelected = (productData: ProductBlockDataModel, preselectServiceId: string): boolean => {
  if (preselectServiceId) return true;

  return productData.subscriptionServices.some((s) => s.preselected === true);
};

export const shoppingCartEventHandler = ({ useVoiceStore }: ShoppingCartEventHandlerProps) => {
  const [dialogState, setDialogState] = useState<DialogState>(DialogState.none);
  const [openButton, setOpenButton] = useState<HTMLElement | undefined>();
  const [productData, setProductData] = useState<ProductBlockDataModel | undefined>();

  const getNumberOfProducts = useVoiceStore((s) => s.numberOfProducts);
  const addMbbOffering = useVoiceStore((s) => s.addMbbOffering);
  const addVoiceOffering = useVoiceStore((s) => s.addVoiceOffering);
  const maxOrders = useVoiceStore((s) => s.getMaxOrders);
  const isBusiness = useVoiceStore((s) => s.isBusiness);

  const setFocusAndResetState = () => {
    setDialogState(DialogState.none);
    if (openButton) setTimeout(() => openButton.focus(), 50);
  };

  const voiceOpenServiceSelector = async (event: CustomEvent<ShoppingCartTransferModel>): Promise<void> => {
    const transferModel = event.detail;

    setOpenButton(transferModel.openButton);

    try {
      const productData = await fetchProductBlockDataService(transferModel.blockId);
      productData.productPage = transferModel.productPage;
      setProductData(productData);

      if (!transferModel.adding) {
        return;
      }

      if (getNumberOfProducts() >= maxOrders()) {
        setDialogState(DialogState.maxOfferings);
        return;
      }

      const isPresetServiceSelected = isAnyServiceIsDefaultSelected(productData, transferModel.preselectServiceId);

      if (productData.showExternalServiceDialog && !isPresetServiceSelected) {
        setDialogState(DialogState.external);
      } else {
        setDialogState(DialogState.none);

        const sub = MapProductDataToSubScription(productData, transferModel.preselectServiceId);
        addVoiceOffering(sub);

        document.dispatchEvent(
          new CustomEvent<Subscription>(SHOPPING_CART_EVENTS.ADD_PRODUCT_NOTIFICATION, {
            detail: sub,
          }),
        );

        showHeader();
      }
    } catch (error) {
      console.error("Failed to fetch product data", error);
      return;
    }
  };

  const mbbAddProductEventHandler = (e: CustomEvent<MbbBundle>) => {
    if (getNumberOfProducts() >= maxOrders()) {
      setDialogState(DialogState.maxOfferings);
      return;
    }

    addMbbOffering(e.detail);

    document.dispatchEvent(
      new CustomEvent<MbbBundle>(SHOPPING_CART_EVENTS.ADD_PRODUCT_NOTIFICATION, {
        detail: e.detail,
      }),
    );

    showHeader();
  };

  const addVoiceOfferingEvent = (e: CustomEvent<VoiceAddToCartEvent>) => {
    if (getNumberOfProducts() >= maxOrders()) {
      setDialogState(DialogState.maxOfferings);
      return;
    }

    const { sub } = e.detail;
    addVoiceOffering(sub);

    document.dispatchEvent(
      new CustomEvent<Subscription>(SHOPPING_CART_EVENTS.ADD_PRODUCT_NOTIFICATION, {
        detail: sub,
      }),
    );

    showHeader();
  };

  useEffect(() => {
    const voiceOpenServiceSelectorEventHandlerAsyncWrapper = (e: CustomEvent<ShoppingCartTransferModel>) => {
      void voiceOpenServiceSelector(e);
    };

    document.addEventListener(
      SHOPPING_CART_EVENTS.OPEN_SERVICE_SELECTOR_VIEW,
      voiceOpenServiceSelectorEventHandlerAsyncWrapper as EventListener,
    );
    document.addEventListener(SHOPPING_CART_EVENTS.ADD_PRODUCT, addVoiceOfferingEvent as EventListener);
    document.addEventListener(Mbb_SHOPPING_CART_EVENTS.ADD_PRODUCT, mbbAddProductEventHandler as EventListener);

    return () => {
      document.removeEventListener(
        SHOPPING_CART_EVENTS.OPEN_SERVICE_SELECTOR_VIEW,
        voiceOpenServiceSelectorEventHandlerAsyncWrapper as EventListener,
      );
      document.removeEventListener(SHOPPING_CART_EVENTS.ADD_PRODUCT, addVoiceOfferingEvent as EventListener);
      document.removeEventListener(Mbb_SHOPPING_CART_EVENTS.ADD_PRODUCT, mbbAddProductEventHandler as EventListener);
    };
  }, []);

  useEffect(() => {
    const max = maxOrders();
    const business = isBusiness();
    const modalContainer = document.querySelector<HTMLElement>("#ice-modal-container");
    if (!modalContainer) {
      throw new Error("Could not open DialogLoader: '#ice-modal-container element' not found");
    }

    render(
      h(DialogLoader, {
        dialogState,
        productData,
        setFocusAndResetState,
        maxOrders: max,
        isBusiness: business,
        useVoiceStore: useVoiceStore,
        openButton: openButton,
      }),
      modalContainer,
    );
  }, [dialogState]);
};
