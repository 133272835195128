import { StorageValue } from "zustand/middleware";

export interface cookieStorageProvider {
  getItem: (name: string) => StorageValue<unknown>;
  setItem: (name: string, data: unknown) => void;
  removeItem: (name: string) => void;
}

export class CookieStorage implements cookieStorageProvider {
  document: Document;
  ttl: number;
  domain: string;
  protocol: string;
  constructor(document: Document, ttl: number, domain: string) {
    this.document = document;
    this.ttl = ttl;
    this.domain = domain;

    this.protocol = window.location.protocol;
  }

  getItem(name: string): StorageValue<unknown> {
    try {
      const match = new RegExp(`(^| )${name}=([^;]+)`).exec(`; ${document.cookie}`);
      if (!match) return null;
      const cookieValue = match[2];
      const value = JSON.parse(cookieValue) as StorageValue<unknown>;
      return value;
    } catch (e) {
      console.error("failed to get data ", e);
      return { state: null };
    }
  }

  setItem(name: string, data: unknown) {
    const serializedData = JSON.stringify(data);

    const expiry = new Date();
    expiry.setTime(expiry.getTime() + this.ttl);

    let secure = "";
    if (this.protocol === "https:") {
      secure = "Secure";
    }

    const cookie = `${name}=${serializedData};expires=${expiry.toUTCString()};path=/;domain=${
      this.domain
    };SameSite=Lax; ${secure}`;

    this.document.cookie = cookie;
  }

  removeItem(name: string) {
    document.cookie = `${name}=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
  }
}

// export class MockStorage implements StorageProvider {
//   clear: (namespace: string) => void;

//   // eslint-disable-next-line @typescript-eslint/no-unused-vars
//   load<T>(namespace: string): Result<T> {
//     return { success: true, value: null };
//   }

//   // eslint-disable-next-line @typescript-eslint/no-unused-vars
//   save(namespace: string, value: unknown): void {
//     return;
//   }
// }
