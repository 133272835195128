import { isEmptyString } from "@shared/Utils";
import { Fragment, type FunctionalComponent } from "preact";
import { h } from "preact";
import { useState } from "preact/hooks";
import { CloseIcon, Price } from "@shared/Components";
import { CTA_BUTTON_TEXTS, type Subscription, type voiceShopStoreStateType } from "../../shop-types";
import type { ProductBlockDataModel, ProductServiceBlockModel } from "../../Helpers/subscription-builder.types";
import { enableFeedbackPopup, showHeader } from "../../Helpers/shopping-cart-utils";
import * as Dialog from "@radix-ui/react-dialog";
import { MapProductDataToSubScription } from "../../shop-cart.store";
import { SHOPPING_CART_EVENTS } from "../shopping-cart-constants";
import { sendGA4AddonEvent } from "@shared/Analytics/ecommerce/voice/voice-addon-analytics";

export type ExternalAddservicesDialogItemsProps = {
  useVoiceStore: voiceShopStoreStateType;
  productData?: ProductBlockDataModel;
  offeringsId?: string;
  sub?: Subscription;
  closeDialog: () => void;
};

type ExternalAddserviceDialogProps = {
  currentServiceTitle: string;
  useVoiceStore: voiceShopStoreStateType;
  productData?: ProductBlockDataModel;
  offeringsId?: string;
  sub?: Subscription;
  closeAndSetFocus?: () => void;
  openButton?: HTMLElement;
};

export const ExternalAddserviceDialog: FunctionalComponent<ExternalAddserviceDialogProps> = ({
  useVoiceStore,
  productData,
  offeringsId,
  currentServiceTitle,
  closeAndSetFocus,
  sub,
  openButton,
}: ExternalAddserviceDialogProps) => {
  const [open, setOpen] = useState(!offeringsId);
  const triggerId = `change-shopping_cart_${offeringsId}`;

  const closeDialog = () => {
    sendGA4AddonEvent("proceed", true);
    setOpenState(false);
  };

  const setOpenState = (state: boolean) => {
    setOpen(state);
    sendGA4AddonEvent(state ? "open" : "close", true);
    if (!state && !offeringsId) enableFeedbackPopup(openButton);

    if (closeAndSetFocus) {
      closeAndSetFocus();
      return;
    }

    // When data in dialog change, dialog lost the reference to the trigger element.
    setTimeout(() => {
      const triggerRef = document.querySelector<HTMLElement>(`#${triggerId}`);
      if (triggerRef) {
        triggerRef.focus();
      }
    }, 15);
  };

  return (
    <Dialog.Root open={open} onOpenChange={setOpenState}>
      {offeringsId && (
        <Dialog.Trigger asChild>
          <button
            type="button"
            id={triggerId}
            className="subscriptions__block__btn link"
            aria-label={`Endre ${currentServiceTitle}`}
            onClick={() => setOpen(true)}
          >
            Endre
            <svg style="width:4.8em;height:4.8em" focusable="false" aria-hidden="true">
              <use xlinkHref="#ice-edit" />
            </svg>
          </button>
        </Dialog.Trigger>
      )}

      <Dialog.Portal>
        <Dialog.Overlay className="radix-modal__overlay" />
        <Dialog.Content className="radix-modal__content addservice-dialog">
          <ExternalAddservicesDialogItems
            productData={productData}
            useVoiceStore={useVoiceStore}
            offeringsId={offeringsId}
            closeDialog={closeDialog}
            sub={sub}
          />
          <Dialog.Close asChild>
            <button type="button" className="radix-modal__close" aria-label="Close">
              <CloseIcon />
            </button>
          </Dialog.Close>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
};

const ExternalAddservicesDialogItems: FunctionalComponent<ExternalAddservicesDialogItemsProps> = ({
  useVoiceStore,
  productData,
  offeringsId,
  closeDialog,
  sub,
}: ExternalAddservicesDialogItemsProps) => {
  const [showMessage, setShowMessage] = useState<boolean>(false);
  const [currentServiceId, setCurrentServiceId] = useState<string | null>(
    sub?.additionalServices.find((service) => service.selected)?.serviceId ?? null,
  );
  const updateService = useVoiceStore((state) => state.updateServiceAndDeselectOthers);
  const addVoiceOffering = useVoiceStore((state) => state.addVoiceOffering);
  const { subscriptionServices } = productData as ProductBlockDataModel;

  const storeService = () => {
    const selectedItem = subscriptionServices.find((x) => x.productId === currentServiceId);
    if (!selectedItem) {
      setShowMessage(true);
      return;
    }

    if (offeringsId) {
      updateService(offeringsId, selectedItem.productId, true);
    } else {
      const sub = MapProductDataToSubScription(productData, selectedItem.productId);
      addVoiceOffering(sub);
      showHeader();
      document.dispatchEvent(
        new CustomEvent<Subscription>(SHOPPING_CART_EVENTS.ADD_PRODUCT_NOTIFICATION, {
          detail: sub,
        }),
      );
    }

    closeDialog();
  };

  return (
    <Fragment>
      <Dialog.Title className="no-focus-outline addservice-dialog__title">
        {isEmptyString(productData.serviceAddTitle) ? "Tilleggstjenester" : productData.serviceAddTitle}
      </Dialog.Title>
      {productData.serviceAddDesc && (
        <Dialog.Description className="addservice-dialog__description">{productData.serviceAddDesc}</Dialog.Description>
      )}

      {subscriptionServices && (
        <ul className="list--unstyled addservice-dialog__items">
          {subscriptionServices.map((service) => (
            <ExternalAddserviceItem
              key={service.productId}
              service={service}
              setServiceId={setCurrentServiceId}
              currentServiceId={currentServiceId}
            />
          ))}
          <div role="alert">
            {showMessage && !currentServiceId && (
              <li className="message-red-border">
                {isEmptyString(productData.noProductSelectedMessage)
                  ? "Ingen tjenester valgt"
                  : productData.noProductSelectedMessage}
              </li>
            )}
          </div>
        </ul>
      )}

      <div className="addservice-dialog__buttons">
        <button className="btn btn--primary addservicepopup__button-add" onClick={storeService}>
          {offeringsId ? CTA_BUTTON_TEXTS.UPDATE : CTA_BUTTON_TEXTS.ADD}
        </button>

        <Dialog.Close asChild>
          <button className="link" aria-label="lukk dialog">
            Lukk
          </button>
        </Dialog.Close>
      </div>
    </Fragment>
  );
};

type ExternalAddserviceItemProps = {
  service: ProductServiceBlockModel;
  setServiceId: (value: string | ((prevState: string) => string)) => void;
  currentServiceId: string;
};

const ExternalAddserviceItem: FunctionalComponent<ExternalAddserviceItemProps> = ({
  service,
  setServiceId,
  currentServiceId,
}: ExternalAddserviceItemProps) => {
  const onChkChangeHandler = (e: Event) => {
    const radioInput = e.target as HTMLInputElement;
    setServiceId(radioInput.value);
  };
  const isSelected = service.productId === currentServiceId;

  return (
    <li key={service.title} name={service.title} className={`addservice-dialog__item-radio`}>
      <label className="radio-frame w-100">
        <input
          name="external-services"
          type="radio"
          onChange={onChkChangeHandler}
          checked={isSelected}
          value={service.productId}
          data-service-name={service.name}
          data-id={`${service.name}:${isSelected ? "unchecked" : "checked"}`}
        />
        <div className={"addservice-dialog__item-radio__content"}>
          <span className={"text--bold-700"}>{service.title}</span>

          {service.price > 0 ? (
            <span className="text--bold-700">
              + <Price amount={service.price} useKr={true} /> <span>/mnd</span>
            </span>
          ) : (
            <span className="addservice-dialog__item-radio__content__included background--green-60">Inkludert</span>
          )}
          <div
            className="addservice-dialog__item-radio__content__info"
            dangerouslySetInnerHTML={{ __html: service.serviceInfoContent }}
          ></div>
        </div>
      </label>
    </li>
  );
};
