import { h, type FunctionalComponent } from "preact";
import { useState } from "preact/hooks";
import type { IceModalItemProps, IceModalProps } from "./ice-modal-models";
import * as Dialog from "@radix-ui/react-dialog";
import { CloseIcon } from "../Icons/icons";
import { getElementChild, type GetElementChildReturnProps } from "./ice-modal-helpers";

export const IceModal: FunctionalComponent<IceModalProps> = ({ linkText, children }: IceModalProps) => {
  const [dialogTitle] = useState<GetElementChildReturnProps>(getElementChild(children, "ice-modal-title"));
  const [dialogClose] = useState<GetElementChildReturnProps>(getElementChild(children, "ice-modal-close"));
  const [dialogContent] = useState<GetElementChildReturnProps>(getElementChild(children, "ice-modal-content"));

  return (
    <Dialog.Root>
      <Dialog.Trigger asChild>
        <button type="button" className="link">
          {linkText}
        </button>
      </Dialog.Trigger>
      <Dialog.Portal>
        <Dialog.Overlay className="radix-modal__overlay" />
        <Dialog.Content className={`radix-modal__content ${dialogContent.className}`}>
          {dialogTitle && (
            <Dialog.Title asChild className={`DialogTitle ${dialogTitle.className}`}>
              {dialogTitle.children}
            </Dialog.Title>
          )}
          {dialogContent.children}

          {dialogClose?.children && dialogClose.children}
          <Dialog.Close className="radix-modal__close" aria-label="Lukk vindu">
            <CloseIcon />
          </Dialog.Close>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
};

export const IceModalElement = ({ className }: IceModalItemProps) => {
  return <div className={className} />;
};
