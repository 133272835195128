import { h, ComponentChildren, FunctionalComponent, Fragment } from "preact";
import { CSSTransition } from "preact-transitioning";
import { useContext, useEffect, useState } from "preact/hooks";
import { IceAnimationHeightContext } from "@shared/Components/IceAnimation/IceAnimationHeightProvider";

type callBackProps = (callerEvent: Event, arg1?: unknown, arg2?: unknown) => void;

type AnimationStateType = {
  callBack: callBackProps;
  event?: Event;
};

export const IceAnimationCreateState = () => {
  return useState<AnimationStateType>({ callBack: null });
};

export const IceAnimationCloseHandler = (
  setAnimationState: (values: AnimationStateType) => void,
  callBack: callBackProps,
) => {
  return (event: Event) => {
    event.preventDefault();
    event.stopImmediatePropagation();
    setAnimationState({
      callBack: callBack,
      event: event,
    });
  };
};

type IceAnimationHeightProps = {
  key: string;
  children: ComponentChildren;
  animationState?: AnimationStateType;
  open?: boolean;
};

export const IceAnimationHeight: FunctionalComponent<IceAnimationHeightProps> = ({
  children,
  animationState,
  open = true,
}: IceAnimationHeightProps) => {
  const [openState, setOpenState] = useState<boolean>(false);

  useEffect(() => {
    setOpenState(open);
  }, [open]);

  useEffect(() => {
    if (!animationState?.callBack) return;
    setOpenState(false);
  }, [animationState]);

  const { stopHeightAnimation } = useContext(IceAnimationHeightContext);

  return (
    <Fragment>
      <CSSTransition
        in={openState}
        alwaysMounted={true}
        classNames="ice-animate-height ice-animate-height-go"
        onEnter={(node: HTMLElement) => {
          if (node && !stopHeightAnimation.value) {
            node.style.height = `${node.scrollHeight}px`;
          }
        }}
        onEntered={(node: HTMLElement) => {
          if (node && !stopHeightAnimation.value) {
            node.style.height = "";
          }
        }}
        onExit={(node: HTMLElement) => {
          if (node && !stopHeightAnimation.value) {
            node.style.height = `${node.scrollHeight}px`;
            // force reflow
            node.clientHeight;
          }
        }}
        onExiting={(node: HTMLElement) => {
          node.style.height = "";
        }}
        onExited={() => {
          if (animationState?.callBack) {
            animationState.callBack(animationState.event);
          }
        }}
      >
        <div>{children}</div>
      </CSSTransition>
    </Fragment>
  );
};
