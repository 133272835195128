export const SHOPPING_CART_EVENTS = {
  ADD_PRODUCT: "ice_shopping-cart_add-product",
  FAMILY_CONTEXT_UPDATED: "ice_shopping-cart_is-family-context-updated",
  ADD_PRODUCT_NOTIFICATION: "ice_shopping-cart_add-product-notification",
  OPEN_SERVICE_SELECTOR_VIEW: "ice_shopping-cart-open-selector-view",
};

export const SHOPPING_CART_STORAGE_KEYS = {
  IS_FAMILY_CONTEXT: "ice-voice_is-family-context",
};

export const Mbb_SHOPPING_CART_EVENTS = {
  ADD_PRODUCT: "ice_mbbshopping-cart_add-product",
  FULL: "ice_mbbshopping-cart_full",
  NOT_FULL: "ice_mbbshopping-cart_not-full",
};
