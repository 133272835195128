import { GAProduct } from "./voice-analytics-repository";
import { GA4ProductCategories } from "./voice-analytics-types";

export const calculateEcommerceValue = (productPrice: number[]) => {
  const validPrices = productPrice.filter((price) => typeof price === "number" && !isNaN(price));
  const totalValue = validPrices.reduce((sum, price) => sum + price, 0);

  return parseFloat(totalValue.toFixed(2));
};

export const extractProductAddons = (products: GAProduct[], productCategories: GA4ProductCategories) => {
  const isAddonService = productCategories?.item_category2?.toLowerCase() === "addon";
  if (isAddonService) return;

  const productAddons = products.filter((product) => product?.category?.toLowerCase().includes("addon"));
  return productAddons
    .map((product) => {
      return product.name;
    })
    .join(", ");
};

export const extractProductCategories = (productCategories: string): GA4ProductCategories => {
  if (productCategories === undefined) return {};

  const [item_category, item_category2, item_category3] = productCategories.split("/");

  return {
    item_category: item_category,
    item_category2: item_category2,
    ...(item_category3 ? { item_category3 } : {}),
  };
};
