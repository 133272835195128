import { h, FunctionalComponent, render, Fragment } from "preact";
import { useContext, useEffect, useState } from "preact/hooks";
import { ShoppingCartBurger } from "./shopping-cart-burger";
import { DropDownTitleView } from "./shopping-cart-layout";
import { OfferingsListView } from "./offerings-list-view";
import { Offering, Subscription, voiceShopStoreStateType } from "../shop-types";
import { shoppingCartEventHandler } from "./shopping-cart-event-handler";
import { clickedOutsideShoppingCartHandler, useTrapTabHandler } from "../Helpers/effects";
import {
  getSubIds,
  highlightSubscriptionCardsFromCart,
  removeSubscriptionCardsBordersAndCounter,
} from "../Helpers/shopping-cart-utils";
import { CSSTransition } from "preact-transitioning";
import { IceAnimationHeightContext } from "../../../Shared/Components/IceAnimation/IceAnimationHeightProvider";
import { sendGA4ViewCartData } from "@shared/Analytics/ecommerce/voice/voice-analytics";
import { sendGA4FunnelData } from "@shared/Analytics/content/funnel-analytics";

export type ShoppingCartProps = {
  shoppingCartRootEl: HTMLElement;
  useVoiceStore: voiceShopStoreStateType;
};

type TrapEventsType = { removeEvent: (() => void) | null };

export const VoiceShoppingCart: FunctionalComponent<ShoppingCartProps> = ({
  useVoiceStore,
  shoppingCartRootEl,
}: ShoppingCartProps) => {
  const [shoppingBurgerRef, setShoppingBurgerRef] = useState<HTMLButtonElement>();
  const [trapEvents, setTrapEvents] = useState<TrapEventsType | null>(null);

  const [openCart, setOpenCart] = useState<boolean>(false);

  const numberOfProducts = useVoiceStore((state) => state.numberOfProducts);

  shoppingCartEventHandler({ useVoiceStore });

  const closeShoppingCart = () => {
    setOpenCart(false);

    if (shoppingBurgerRef) {
      shoppingBurgerRef.focus();
    }
  };

  clickedOutsideShoppingCartHandler(openCart, closeShoppingCart);

  const setShoppingCartFocus = (cart: HTMLElement) => {
    if (openCart && cart) {
      cart.focus();
      trackOfferingsForGA4(offeringsList, isMbb(), isBusiness());
    }

    // Need to add to a variable to prevent invoke immediately
    setTrapEvents({ removeEvent: useTrapTabHandler(cart) });
  };

  const cleanUpTrapEvents = () => {
    if (trapEvents && trapEvents.removeEvent) {
      trapEvents.removeEvent();
    }
  };

  const onKeyDownHandler = (e: KeyboardEvent): void => {
    if (e.key === "Escape") {
      closeShoppingCart();
    }
  };

  const isMbb = useVoiceStore((state) => state.isMbb);
  const isBusiness = useVoiceStore((state) => state.isBusiness);
  const offeringsList = useVoiceStore((state) => state.offerings);

  const updateService = useVoiceStore((state) => state.updateService);
  const removeOffering = useVoiceStore((state) => state.removeOffering);

  const getPrices = useVoiceStore((state) => state.getPrice);
  const [totalSum, setTotalSum] = useState<number>(getPrices().total);

  useVoiceStore.subscribe((store) => {
    if (store.offerings.length === 0) {
      closeShoppingCart();
    }
  });

  useEffect(() => {
    const productCount = numberOfProducts();
    const sum = getPrices();
    setTotalSum(sum.total);

    render(
      h(ShoppingCartBurger, {
        productCount,
        totalSum: sum.total,
        setOpenShoppingCart: setOpenCart,
        openShoppingCart: openCart,
        setShoppingBurgerRef,
      }),
      shoppingCartRootEl,
    );

    const body = document.querySelector<HTMLElement>("body");
    if (!body) throw new Error("Body element not found on document");

    if (openCart) {
      body.classList.add("disable-scrolling");
    } else {
      body.classList.remove("disable-scrolling");
    }
  }, [openCart, offeringsList]);

  useEffect(() => {
    if (offeringsList.length === 0) {
      removeSubscriptionCardsBordersAndCounter();
    } else if (!isMbb()) {
      highlightSubscriptionCardsFromCart(getSubIds(offeringsList));
    }
  }, [offeringsList]);

  const removeOfferingHandler = (id: string) => {
    removeOffering(id);
  };

  const removeServiceHandler = (id: string, serviceName: string) => {
    updateService(id, serviceName, false);
  };

  const { stopHeightAnimation } = useContext(IceAnimationHeightContext);

  const trackOfferingsForGA4 = (offeringsList: Offering[], isMbb: boolean, isBusiness: boolean): void => {
    if (offeringsList.length === 0) return;

    void sendGA4ViewCartData(offeringsList, isMbb, isBusiness);
    void sendGA4FunnelData(offeringsList as Subscription[]);
  };

  return (
    <Fragment>
      <div
        className={`${openCart ? "shopping-cart--open-overlay" : ""}`}
        aria-hidden={`${openCart ? "false" : "true"}`}
      />

      <CSSTransition
        in={openCart}
        classNames="shopping-cart shopping-cart--open"
        onEnter={() => {
          stopHeightAnimation.value = true;
        }}
        onEntered={(el: HTMLElement) => {
          stopHeightAnimation.value = false;
          setShoppingCartFocus(el);
        }}
        addEndListener={(node, done) => {
          node.addEventListener("transitionend", done, { once: true, capture: false });
        }}
        onExited={() => {
          cleanUpTrapEvents();
        }}
      >
        <div
          role="dialog"
          aria-modal="true"
          aria-labelledby="shopping-cart-heading"
          tabIndex={-1}
          onKeyDown={onKeyDownHandler}
        >
          <DropDownTitleView
            onKeyDownHandler={onKeyDownHandler}
            closeShoppingCart={closeShoppingCart}
            productCount={numberOfProducts()}
          />
          {numberOfProducts() === 0 && (
            <div className="subscriptions--empty p-075">
              <span>Det er ingen varer i handlekurven</span>
            </div>
          )}

          {numberOfProducts() > 0 && (
            <OfferingsListView
              removeOffering={removeOfferingHandler}
              offerings={offeringsList}
              totalSum={totalSum}
              removeService={removeServiceHandler}
              useVoiceStore={useVoiceStore}
            />
          )}
        </div>
      </CSSTransition>
    </Fragment>
  );
};
