const removeUrlParams = (urlInput: string, param: string): string => {
  const url = new URL(urlInput);
  url.searchParams.delete(param);
  const urlStringValue = url.toString();
  // Make sure to remove trailing question mark, still a bug in Safari: https://bugs.webkit.org/show_bug.cgi?id=193022
  return urlStringValue.endsWith("?") ? urlStringValue.slice(0, -1) : urlStringValue;
};

export const getBaseBuilderUrl = (): string => {
  return ["planid", "blockid", "id"].reduce(removeUrlParams, window.location.href);
};
