export type BaseGA4MbbEvent = {
  event: "add_to_cart" | "remove_from_cart" | "begin_checkout";
  ecommerce: BaseGA4Ecommerce;
};

export type GA4EventTypes = "add_to_cart" | "remove_from_cart";

type BaseGA4Ecommerce = {
  currency: "NOK";
  value: number;
  items: GA4Product[];
};

export type GA4Product = {
  item_id: string;
  item_name: string;
  item_brand: "Ice";
  item_variant: string; // variant
  item_binding?: string; // dimension 18, "nei" | "n" n = antall måneder med bindingstid
  item_business_unit?: "B2C" | "B2B"; // Dimension5, business unit. "B2C" eller "B2B"
  affiliation: "ice.no"; // Can be changed to an affiliate id when that is implemented. E.g. "bytt.no".
  quantity: number; // quantity
  price: number; // price
  item_bundle?: "Mobilt Bredbånd" | "Mobilabonnement";
  coupon?: string; // probably not relevant?
  discount?: number; // probably not relevant?
  index?: number;
  item_list_id?: string; // probably not relevant?
  item_list_name?: string; // probably not relevant?
  item_product_type?: "Router" | "Service"; // "Service" | "SIM" | "Router"
  item_category?: string; // "Service"
  item_category2?: string; // "MBB" | "Mobilabonnement"
  item_category3?: string; // "Data"
  item_category4?: string;
  item_category5?: string;
  location_id?: string;
  item_addons?: string;
};

export type GA4ProductCategory = {
  item_category: "Service" | "Hardware";
  item_category2: "MBB" | "Router" | "Addon";
  item_category3?: "data";
};

export type GA4ProductCategory1 = Pick<GA4ProductCategory, "item_category">["item_category"];
export type GA4ProductCategory2 = Pick<GA4ProductCategory, "item_category2">["item_category2"];
export type GA4ProductCategory3 = Pick<GA4ProductCategory, "item_category3">["item_category3"];

export const createProductCategories = (
  item_category: GA4ProductCategory1,
  item_category2: GA4ProductCategory2,
  item_category3?: GA4ProductCategory3,
): GA4ProductCategory => {
  return {
    item_category,
    item_category2,
    ...(item_category3 ? { item_category3 } : {}),
  };
};
