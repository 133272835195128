import type { MbbBundle } from "Scripts/Src/Components/ShoppingCart/shop-types";
import { type GA4EventTypes, type GA4Product, createProductCategories } from "./mbb-b2c-analytics.models";
import { clearPreviousEcommerceObjectToDataLayer, pushToDataLayer } from "@shared/Analytics/data/data-layer.facade";
import { addIndexToItems } from "@shared/Utils/analytics-utils";

export const getGA4BundleForSubscriptionAndRouter = (bundle: MbbBundle) => {
  const subscription: GA4Product = {
    item_id: bundle.planId,
    item_name: `Mobilt Bredbånd ${bundle.name}`,
    item_variant: `MBB ${bundle.subscriptionname}`,
    item_brand: "Ice",
    item_bundle: "Mobilt Bredbånd",
    affiliation: "ice.no",
    item_business_unit: getBusinessDomain(bundle),
    quantity: 1,
    price: calculateProductItemPrice(bundle.monthlyprice),
    ...createProductCategories("Service", "MBB", "data"),
  };

  const router: GA4Product = {
    item_id: bundle.routerId,
    item_name: bundle.routername,
    affiliation: "ice.no",
    item_business_unit: getBusinessDomain(bundle),
    item_variant: bundle.routername,
    item_bundle: "Mobilt Bredbånd",
    item_brand: "Ice",
    item_product_type: "Router",
    quantity: 1,
    price: bundle.routerprice, // One time purchases are not multiplied by 24
    ...createProductCategories("Hardware", "Router"),
  };

  const shipping: GA4Product = {
    item_id: "", // not currently available
    item_name: "Frakt",
    item_brand: "Ice",
    item_binding: "Nei",
    item_bundle: "Mobilt Bredbånd",
    item_business_unit: getBusinessDomain(bundle),
    item_product_type: "Service",
    item_variant: "Frakt",
    affiliation: "ice.no",
    quantity: 1,
    price: 99,
    ...createProductCategories("Service", "Addon"),
  };

  return [subscription, router, shipping];
};

const getBusinessDomain = (subscription: MbbBundle) => {
  return subscription.isbusiness === true ? "B2B" : "B2C";
};

const calculateProductItemPrice = (productPrice: number) => {
  const price = productPrice * 24;
  return Number.parseInt(price.toFixed(2), 10);
};

const calculateMbbValue = (products: GA4Product[]) => {
  const value = products.reduce((sum, { price }) => sum + price, 0);
  return Number.parseInt(value.toFixed(2), 10);
};

/* GA4 implementation  */
export const sendGA4Event = (eventType: GA4EventTypes, subscription: MbbBundle) => {
  clearPreviousEcommerceObjectToDataLayer();

  const items = getGA4BundleForSubscriptionAndRouter(subscription);

  pushToDataLayer({
    event: eventType,
    ecommerce: {
      currency: "NOK",
      value: calculateMbbValue(items),
      items: addIndexToItems(items),
    },
  });
};
