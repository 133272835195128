import { Subscription } from "Scripts/Src/Components/ShoppingCart/shop-types";
import { pushToDataLayer } from "../data/data-layer.facade";
import { WebStorage } from "@shared/storage";
import EcommerceAnalyticsRepository from "../ecommerce/voice/voice-analytics-repository";
import { isMobileBroadbandPath } from "@shared/Utils/analytics-utils";

export type BaseGA4FunnelTracking = {
  event: "mobilabonnement_funnel";
  step: number;
  funnel_step: FunnelStep;
  item_id_list: string;
  item_names_list?: string;
  item_business_unit: "B2C" | "B2B";
  item_category_list?: string[] | string;
  add_to_cart_location: string[] | string;
};

export enum FunnelStep {
  Oppsummering = "Oppsummering",
  Tilleggsvelger = "Tilleggsvelger",
  Personalia = "Personalia",
}

const funnelStepMapping: Record<FunnelStep, number> = {
  [FunnelStep.Oppsummering]: 2,
  [FunnelStep.Tilleggsvelger]: 2.5,
  [FunnelStep.Personalia]: 3,
};

const serviceCategoryMapping: Record<string, string> = {
  "Data Frihet": "Service/Addon/data",
  "Data-SIM": "Service/Addon/sim",
  "Tvilling-SIM": "Service/Addon/sim",
  "Netflix Premium": "Service/Addon/vas",
  "Netflix Standard": "Service/Addon/vas",
  "Netflix Basis": "Service/Addon/vas",
};

const ecommerceRepository = new EcommerceAnalyticsRepository(new WebStorage(localStorage));

const getCategorySummary = async (subscriptions: Subscription[]): Promise<string> => {
  const categories = await Promise.all(
    subscriptions.map(async (subscription) => {
      const [product] = await ecommerceRepository.getProducts([subscription.planId]);
      const selectedAddons = subscription.additionalServices.filter((service) => service.selected);
      const addonCategories =
        selectedAddons.length > 0 ? selectedAddons.map((addon) => serviceCategoryMapping[addon.title]) : [];

      return {
        productCategory: product?.category,
        addonCategories,
      };
    }),
  );

  return JSON.stringify(categories);
};

const getNameSummary = async (subscriptions: Subscription[]): Promise<string> => {
  const names = await Promise.all(
    subscriptions.map(async (subscription) => {
      const [product] = await ecommerceRepository.getProducts([subscription.planId]);
      const selectedAddons = subscription.additionalServices.filter((service) => service.selected);
      const addonNames = selectedAddons.length > 0 ? selectedAddons.map((addon) => addon.title) : [];

      return {
        productName: product?.name,
        addonNames,
      };
    }),
  );

  return JSON.stringify(names);
};

const getIdSummary = (subscriptions: Subscription[]): string => {
  const ids = subscriptions.map((subscription) => {
    const selectedAddons = subscription.additionalServices.filter((service) => service.selected);
    const addonIds = selectedAddons.length > 0 ? selectedAddons.map((addon) => addon.serviceId) : [];

    return {
      productId: subscription.planId,
      addonIds,
    };
  });

  return JSON.stringify(ids);
};

export async function sendGA4FunnelData(
  subscriptions: Subscription[],
  funnelStep: FunnelStep = FunnelStep.Oppsummering,
): Promise<void> {
  if (isMobileBroadbandPath()) return;

  const funnelTrackingEvent: BaseGA4FunnelTracking = {
    event: "mobilabonnement_funnel",
    funnel_step: funnelStep,
    step: funnelStepMapping[funnelStep],
    item_id_list: getIdSummary(subscriptions),
    item_names_list: await getNameSummary(subscriptions),
    item_business_unit: "B2C",
    add_to_cart_location: subscriptions.map((subscription) => subscription.productPage).join(", "),
    item_category_list: await getCategorySummary(subscriptions),
  };

  pushToDataLayer(funnelTrackingEvent);
}
