import { type FunctionalComponent, h } from "preact";
import { isInteger } from "../../Utils";

interface PriceFormatProps {
  amount: number | null;
  useKr?: boolean;
  addMnd?: boolean;
  testIdPrefix?: string;
}

export const Price: FunctionalComponent<PriceFormatProps> = ({
  amount = 0,
  useKr = false,
  addMnd = false,
  testIdPrefix = "",
}: PriceFormatProps) => {
  const checkedAmount = amount ?? 0;

  const ending = useKr ? " kr" : ",&ndash;";

  return isInteger(checkedAmount) ? (
    <span>
      <span data-testid={`${testIdPrefix}price`}>{checkedAmount}</span>
      <span dangerouslySetInnerHTML={{ __html: ending }}></span>
      {addMnd && <span>/mnd</span>}
    </span>
  ) : (
    <span>{checkedAmount.toFixed(2)}</span>
  );
};
