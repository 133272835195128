import type { CookieStorage } from "@shared/cookieStorage";
import type { StoreApi, UseBoundStore } from "zustand";

export const CTA_BUTTON_TEXTS = {
  ADD: "Legg i handlekurv",
  UPDATE: "Lagre",
};

export type ShoppingCartStoreOptions = {
  usePCMOfferings: boolean;
  cookieJar: CookieStorage;
  namespace: string;
  isMbb: boolean;
  isBusiness: boolean;
  maxOrders: number;
  shippingPriceMbb: number;
};

export interface VoiceShopStoreStateModel {
  offerings: Offering[];
  updateService: (productId: string, serviceId: string, selected: boolean) => void;
  updateServiceAndDeselectOthers: (productId: string, serviceId: string, selected: boolean) => void;
  getOffering: (id: string) => Offering;
  removeOffering: (id: string) => void;
  numberOfProducts: () => number;
  getPrice: () => Prices;
  addMbbOffering: (model: MbbBundle) => void;
  addVoiceOffering: (model: Subscription) => void;
  getOfferings: () => Offering[];
  getMaxOrders: () => number;
  isMbb: () => boolean;
  isBusiness: () => boolean;
  usePCMOfferings: () => boolean;
}

export type voiceShopStoreStateType = UseBoundStore<StoreApi<VoiceShopStoreStateModel>>;

export type BaseOfferingType = {
  name: string;
  offeringId: string;
  offeringType: "Voice" | "MBB";
};

export type BaseAdditionalServices = {
  selected: boolean;
  serviceId: string;
};

export type AdditionalServices = BaseAdditionalServices & {
  title: string;
  price: number;
};

export type Subscription = BaseOfferingType & {
  offeringType: "Voice";
  additionalServices: AdditionalServices[];
  planId: string;
  price: number;
  pricenocampaign: number;
  blockId: string;
  builderUrl: string;
  showExternalServiceDialog: boolean;
  productPage: "plp" | "pdp";
};

export type baseMbbBundle = BaseOfferingType & {
  planId: string;
};

export type MbbBundle = baseMbbBundle & {
  offeringType: "MBB";
  routername: string;
  monthlyprice: number;
  subscriptionname: string;
  monthlypricenocampaign: number;
  routerId: string;
  subscriptionId: string;
  routerprice: number;
  isbusiness: boolean;
};

export type Offering = Subscription | MbbBundle;

export type UpdateSubscriptionServicesModel = {
  sessionId: string;
  blockId: string;
  services: BaseAdditionalServices[];
};

export type ShoppingCartTransferModel = {
  sessionId: string;
  blockId: string;
  openButton: HTMLElement;
  openFromShoppingCart: boolean;
  update: boolean;
  services?: BaseAdditionalServices[];
  baseUrl: string;
  adding?: boolean;
  productPage: "plp" | "pdp";
  preselectServiceId: "";
};

export type VoiceAddToCartEvent = {
  sub: Subscription;
};

export type MbbPrices = {
  total: number;
  totalAfterCampaign: number;
  shippingPrice: number;
  totalUpfront: number;
};

export type VoicePrices = {
  total: number;
};

export type Prices = MbbPrices | VoicePrices;

export type AbstractSubscriptionInfo = {
  id: string;
  name: string;
};
