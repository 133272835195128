import * as Dialog from "@radix-ui/react-dialog";
import { type Attributes, type ComponentChildren, Fragment, type VNode, h, toChildArray } from "preact";

export interface ModalAttributes extends Attributes {
  className: string;
}

export type GetElementChildReturnProps = {
  children: VNode<Attributes>;
  className: string;
};

export const getElementChild = (
  children: ComponentChildren,
  elementType: string,
): GetElementChildReturnProps | undefined => {
  const actualChildren = (toChildArray(children)[0] as VNode<Attributes>).props.children;
  const childList = toChildArray(actualChildren);

  const content = childList.find((child) => {
    if (typeof child === "object" && child.type === elementType) return true;
    return false;
  }) as VNode<ModalAttributes>;

  if (!content) return undefined;

  if (elementType === "ice-modal-close") {
    return {
      children: <Dialog.Close class={content.props.className}>{content.props.children}</Dialog.Close>,
      className: "",
    };
  }

  return {
    children: <Fragment>{content.props.children}</Fragment>,
    className: content.props.className ? content.props.className : "",
  };
};
