type LoadSucceeded<T> = { success: true; value: T | null };
type LoadFailed = { success: false; error: Error };
type Result<T> = LoadSucceeded<T> | LoadFailed;

export interface StorageProvider {
  load: <T>(namespace: string) => Result<T>;
  save: (namespace: string, data: unknown) => void;
  clear: (namespace: string) => void;
}

export class WebStorage implements StorageProvider {
  storageMedium: Storage;
  constructor(storageMedium: Storage) {
    this.storageMedium = storageMedium;
  }

  load<T>(namespace: string): Result<T> {
    const data = this.storageMedium.getItem(namespace);
    if (!data) return { success: true, value: null };
    try {
      const value = JSON.parse(data) as T;
      return { success: true, value };
    } catch (e) {
      return { success: false, error: e };
    }
  }

  save(namespace: string, data: unknown): void {
    const serializedData = JSON.stringify(data);
    this.storageMedium.setItem(namespace, serializedData);
  }

  clear(namespace: string): void {
    this.storageMedium.removeItem(namespace);
  }
}

export class CookieStorageOld implements StorageProvider {
  document: Document;
  ttl: number;
  domain: string;
  protocol: string;
  constructor(document: Document, ttl: number, domain: string) {
    this.document = document;
    this.ttl = ttl;
    this.domain = domain;

    this.protocol = window.location.protocol;
  }

  load<T>(namespace: string): Result<T> {
    try {
      const match = new RegExp(`(^| )${namespace}=([^;]+)`).exec(`; ${document.cookie}`);
      if (!match) return { success: true, value: null };
      const cookieValue = match[2];
      const value = JSON.parse(cookieValue) as T;
      return { success: true, value: value };
    } catch (e) {
      return { success: false, error: e };
    }
  }

  save(namespace: string, data: unknown): void {
    const serializedData = JSON.stringify(data);
    const expiry = new Date();
    expiry.setTime(expiry.getTime() + this.ttl);

    let secure = "";
    if (this.protocol === "https:") {
      secure = "Secure";
    }

    const cookie = `${namespace}=${serializedData};expires=${expiry.toUTCString()};path=/;domain=${
      this.domain
    };SameSite=Lax; ${secure}`;

    this.document.cookie = cookie;
  }

  clear(namespace: string): void {
    document.cookie = `${namespace}=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
  }
}

export class MockStorage implements StorageProvider {
  clear: (namespace: string) => void;

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  load<T>(namespace: string): Result<T> {
    return { success: true, value: null };
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  save(namespace: string, value: unknown): void {
    return;
  }
}
